import loader from "./Loader";

window.loader = loader;
globalThis.loader = loader;

document.querySelectorAll("[data-loader]").forEach((element) => {
    const value = element.dataset["loader"];

    switch (value) {

        case 'corner':
            new loader(element, {
                style: 'corner',
                remove: true
            });
            break;

        default:
            new loader(element);
            break;
    }
});
