import { Fancybox } from '@fancyapps/ui'

document.querySelectorAll(`[data-galerie]`).forEach((element) => {
    const value = element.dataset['galerie']

    switch (value) {
        default:
            Fancybox.bind('[data-fancybox]', {})
            break
    }
})
