import '../../sassy/component/back-top/js/init.js'
import '../../sassy/component/form/js/init.js'
import '../../sassy/component/loader/js/init.js'
import './simplebar.js'
import './sliders.js'
import './galerie.js'
import './map.js'
import './menuTop.js'

// Récupérer l'URL actuelle et définir l'ID du body
document.addEventListener('DOMContentLoaded', function () {
    const url = window.location.pathname
    const pageName = url.split('/').pop().split('.')[0] || 'accueil'
    document.body.id = pageName
})
