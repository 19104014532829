if (document.getElementById('navigation')) {
    const navigation = document.getElementById('navigation')
    const nagigationHeight = navigation.offsetHeight

    navigation.style.setProperty('--navigation-height', `${nagigationHeight}px`)

    // Function ScrollTo
    const scrollToFunction = () => {
        let y = window.scrollY

        if (y > 200) {
            navigation.classList.add('menu__fixed')
        } else {
            navigation.classList.remove('menu__fixed')
            navigation.classList.remove('menu__translate')
        }

        if (y > 250) {
            navigation.classList.add('menu__translate')
        }
    }

    // Event Click
    window.addEventListener('scroll', scrollToFunction)
}
