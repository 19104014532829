export default class loader {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({
            remove: false,
            style: 'pulse',
            position: 'loader--initial',
        }, options);
        this.init();
    }
    init() {
        const loader = this.selector
        const content = document.querySelector('html')
        loader.classList.add(this.options.position)

        if (this.options.remove) {
            content.style.overflowY = "hidden"
            window.addEventListener('load', () => {
                loader.classList.add("loader--hide")
                content.style.overflowY = "scroll"
                setTimeout(() => {
                    loader.remove()
                },'2000')
            });
        }

        if (this.options.style == "pulse") {
            const span = document.createElement("span");
            loader.appendChild(span);
        }

        if (this.options.style == "corner") {
            const spinner = document.createElement("div");
            const cornerBottom = document.createElement("div");
            const cornerTop = document.createElement("div");
            const container = document.createElement("div");
            const square = document.createElement("div");
            spinner.className = "loader__spinner";
            container.className = "loader__container";
            square.className = "loader__square";
            cornerTop.className = "loader__corner--top";
            cornerBottom.className = "loader__corner--bottom";
            loader.appendChild(spinner);
            spinner.appendChild(container);
            spinner.appendChild(square);
            container.appendChild(cornerTop);
            container.appendChild(cornerBottom);
        }
    }
}
