import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

document.querySelectorAll(`[data-slider]`).forEach((element) => {
    const value = element.dataset['slider']
    const addClass = {
        arrows: 'splide__arrows arrows',
        arrow: 'splide__arrow arrow',
        prev: 'splide__arrow--prev',
        next: 'splide__arrow--next ',
        pagination: 'splide__pagination pagination',
        page: 'splide__pagination__page pagination__page'
    }
    switch (value) {
        // case 'hide':
        //     new Splide(element, {
        //         type: 'loop',
        //         autoplay: true,
        //         arrows: false,
        //         classes: addClass
        //     }).mount()
        //     break
        // case 'products':
        //     new Splide(element, {
        //         perPage: 3,
        //         focus: 'center',
        //         classes: addClass
        //     }).mount()
        //     break

        case 'events':
            new Splide(element, {
                type: 'loop',
                autoplay: true,
                perPage: 3,
                classes: addClass,
                gap: '2rem',
                arrows: false,

                breakpoints: {
                    1024: {
                        perPage: 2
                    },
                    768: {
                        perPage: 1
                    }
                }
            }).mount()
            break

        // case 'infinite':
        //     new Splide(element, {
        //         type: 'loop',
        //         perPage: 4,
        //         padding: '5rem',
        //         arrows: false,
        //         pagination: false,
        //         drag: 'free',
        //         height: '6rem',
        //         classes: addClass,
        //         autoplay: true,
        //         autoScroll: {
        //             speed: 1
        //         }
        //     }).mount({ AutoScroll })
        //     break

        default:
            // new Splide(element, {
            //     autoplay: true,
            //     type: 'loop',
            //     classes: addClass
            // }).mount()
            break
    }
})
