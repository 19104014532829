import MapLocation from '../../sassy/component/map/js/Map.js'

window.MapLocation = MapLocation
globalThis.MapLocation = MapLocation

// document.querySelectorAll("[data-map]").forEach((element) => {
//     const value = element.dataset["map"];
//     switch (value) {
//         default:
//             new MapLocation(element, {
//                 name: element.getAttribute("data-map-name"),
//                 pathMarker: element.getAttribute("data-map-marker-path"),
//                 lat: parseFloat(element.getAttribute("data-map-lat")),
//                 lng: parseFloat(element.getAttribute("data-map-lng")),
//                 zoom: element.getAttribute("data-map-zoom")
//             });
//             break;
//     }
// });
