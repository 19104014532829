import JustValidate from './JustValidate';
import { fieldEmail, fieldName, fieldPassword } from './validator-types';

window.JustValidate = JustValidate;
globalThis.JustValidate = JustValidate;
document.querySelectorAll(`[data-form]`).forEach((element) => {
    const validator = new JustValidate(element, 'fr').validator;
    const fields = validator.form;

    Array.from(fields).forEach((element) => {
        const check = 'control';
        let fieldType;

        if (element.id.includes(check)) {
            const id = element.id.replace(`${check}_`, '');
            fieldType = `field${id.charAt(0).toUpperCase() + id.slice(1)}`;
            
            switch (fieldType) {
                case 'fieldEmail':
                    fieldType = fieldEmail;
                    break;
                
                case 'fieldPassword':
                    fieldType = fieldPassword;
                    break;

                default:
                    fieldType = fieldName;
                    break;
            }

            validator.addField(`#${element.id}`, fieldType, {
                errorsContainer: `#control_${id}-errors-container`,
            });
        }
    });
});
